html {
  background-color: white;
}

#root {
  /*  height: 100vh;  */
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 0;
  /* 
 border-top: 1px dashed silver;
 */
}
.page {
  position: relative;
  top: 50px;
  background-color: red;
}
.topRow {
  border-bottom: 1px solid black;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: white;
  z-index: 100;
  padding-left: 24px;
  padding-right: 24px;
}

.topRow > h2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.stdFlexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.stdFlexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.stdFlexColCenter300 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin: 0 auto;
}

.centerText {
  text-align: center;
  margin-bottom: 16px;
}

.addBotMenuItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loginWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30vh;
  padding: 16px;
}

.gameWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  margin-bottom: 16px;
}

.radio-group {
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
  position: absolute;
  left: 27px;
  top: -45px;
}

.radio-container {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  font-size: 12px;
  align-items: center;
}

.modal {
  position: "absolute" as "absolute";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: "background.paper";
  border: "2px solid #000";
  box-shadow: 24;
  padding: 4;
}

.summaryCard {
  margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {

  

  .loginWrapper {
    height: 50vh;
  }
  
  .radio-group {
    gap: 20px;
  }
  
  .gameWrapper > div {
    width: 400px;
  }
  .mainCard > div {
    flex-grow: 1;
  }
}

@media only screen and (max-width: 450px) {

  .topRow {
    font-size: 12px;
  }
  div.plot {
    display: none;
  }

  .MuiTabPanel-root {
    padding: 16px !important;
  }
}


.colorPrimary {
  color: rgb(0, 146, 215);
}

.tabSubText {
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  left: 48px;
}

.dump {
  color: #19857b;
}

.MuiCallout-root.MuiCallout-warning {
  color: #1a2027;
  background-color: rgba(255, 249, 235, 0.6);
  border-color: #ffd048;
}

.MuiCallout-root {
  padding: 16px;
  margin: 16px 0;
  border: 1px solid;
  border-radius: 10px;
}

