.cardSection {
  display: grid;
  grid-template-columns: repeat(2, minmax(170px, 400px));
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.5em 1em;
}

/*  !!!CARDSECTION INNER COMPONENTS!!!  */

/* MAIN-CARD */
.mainCard {
  display: flex;
  /* width: 400px; */
  padding: 8px;
  /* margin-bottom: 8px; */
  min-height: 160px;
}

.mainCard > div {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
}

.mainCard > div:first-child {
  width: auto;
  flex-grow: 2;
}

.mainCard > div > div {
  display: flex;
  gap: 16px;
  align-items: center;
}

/* SUB-CARD */
.w100 {
  width: 180px;
}

.subCard {
  /* width: 400px; */
  min-height: 167px;
}

.subCard i {
  font-size: 12px;
}

/* ORDER-INPUT-CARD */
.orderInputCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
}

.placeOrderButton {
  height: 4.5em;
}

/* PROGRESS-CARD */
.progressCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
}

.pieCharts {
  display: flex;
  justify-content: space-evenly;
}

.progressCard i {
  font-size: 12px;
}
.pieChartUnit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PieStackWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pieChartShadow {
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(1, 0, 0.5, 2) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  box-shadow: 0px 6px 3px -3px rgba(0, 0, 0, 0.2),
    0px 3px 3px 0px rgba(0, 0, 0, 0.14), 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  border-radius: 50%;
  width: 46px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  position: absolute;
  z-index: 1;
}

@media only screen and (max-width: 800px) {
  .cardSection {
    grid-template-rows: 1fr 1fr 0.5fr 0.5fr;
    gap: 4px;
  }
  .mainCard > div.plot {
    display: none;
    /* visibility: hidden; */
  }
  .cardIcon {
    display: none;
    /* visibility: hidden; */
  }
  .w100 {
    width: 150px;
  }

  .mainCard {
    height: 150px;
    min-height: 100px;
  }
  .subCard {
    height: 150px;
    min-height: 100px;
  }

  .shakyShaky {
    grid-row: 1/1;
    grid-column: 2/3;
  }

  .orderInputCard {
    flex-direction: row;
    padding: 8px;
    grid-row: 3/3;
    grid-column: 1/3;
  }

  .placeOrderButton {
    font-size: x-small;
    height: 56px;
    width: 180px;
  }

  .placeOrderInput {
    height: auto;
  }

  .progressCard {
    grid-row: 4/4;
    grid-column: 1/3;
  }
}

.boxShadow {
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  border-radius: 4px;
}
